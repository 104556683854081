<template>
  <div class="ads-form fill-height" v-if="chromeData">
    <Alert :show="showAlert && !!showError?.message">
      <SvgSprite symbol="icons-alert" size="0 0 14 11" />
      <span>Erreur : {{ showError?.message }}</span>
    </Alert>
    <BackBtn />
    <div class="wrapper wrapper--small p-l">
      <h3 class="mb-m">Ajouter une petite annonce</h3>

      <form ref="formel" @submit.prevent="onSubmit" method="POST">
        <div class="mb-s">
          <h5 class="mb-xxs">Titre de la petite annonce *</h5>
          <FormInput name="title" :required="true" />
        </div>

        <label class="m-s">
          <h5 class="mb-xxs">Description *</h5>
          <textarea name="text" required></textarea>
        </label>

        <div class="mb-s">
          <h5 class="mb-xxs">Catégorie *</h5>
          <SelectCustom
            name="category"
            placeholder="Choisissez une catégorie"
            :hasParent="category"
            :data="chromeData.lists.adsCategories"
          />
        </div>

        <div class="photos m-s">
          <h5 class="mb-xxs">Photos (1-5 photos) *</h5>
          <Upload
            ref="uploadPhotos"
            name="photos[]"
            icon="image"
            :max="5"
            :options="{
              allowFileTypeValidation: true,
              acceptedFileTypes: ['image/*'],
              allowFileSizeValidation: true,
              maxFileSize: '5MB',
            }"
          />
        </div>

        <div class="error m-m" v-if="showError?.message">
          <h4>{{ showError.message }}</h4>

          <ul>
            <li v-for="(val, key) in showError?.errors" :key="key">
              {{ val.join(',') }}
            </li>
          </ul>
        </div>

        <Btn class="send mt-s">Je publie</Btn>
      </form>
    </div>

    <Feedback v-model:visible="feedbackVisible" :badge="badgeFeedback">
      <template v-if="loading">
        <h4 class="mb-xxs">Document en cours d'envoi...</h4>
        <p>Ta petite annonce est en cours d'envoi...</p>
      </template>

      <template v-else>
        <h4 class="mb-xxs">Merci !</h4>
        <p>
          Ta petite annonce a bien été envoyée. Après vérification, elle sera
          publiée et disponible pour tous les Animateurs et Animatrices.
        </p>
      </template>
    </Feedback>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, reactive } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { create } from '@/guides/endpoints/ads'
import { useRoute } from 'vue-router'

import Alert from '@/components/Alert.vue'
import BackBtn from '@/components/BackBtn.vue'
import Btn from '@/components/Btn.vue'
import Feedback from '@/components/Feedback.vue'
import FormInput from '@/components/FormInput.vue'
import SelectCustom from '@/components/SelectCustom.vue'
import Upload from '@/components/Upload.vue'

export default defineComponent({
  name: 'ads-form',
  components: {
    Alert,
    BackBtn,
    Btn,
    Feedback,
    FormInput,
    SelectCustom,
    Upload,
  },
  setup() {
    const route = useRoute()
    const category = computed(() => route?.params?.subcat)
    console.log('😺 SubCat', category.value)

    const chromeData = ref()
    const showAlert = ref(false)
    const uploadPhotos = ref()

    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')

    chrome().then(r => {
      chromeData.value = r.data
    })

    const form = reactive({})

    const feedbackVisible = ref(false)
    const loading = ref(false)

    const showFeedback = () => {
      feedbackVisible.value = true
    }

    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error

      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new FormData(formel.value)

      const photos = uploadPhotos.value.getFiles()
      data.delete('photos[]')

      if (photos.length) {
        photos.forEach((el: any) => {
          data.append('photos[]', el.file)
        })
      }

      create(data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      feedbackVisible,
      showFeedback,
      onSubmit,
      loading,
      formel,
      showError,
      badgeFeedback,
      showAlert,
      uploadPhotos,
      chromeData,
      category,
    }
  },
})
</script>

<style lang="scss" scoped>
.ads-form {
  @include form-base;

  background: $c-white;

  ::v-deep(.select-custom) {
    background-color: $athens-gray;
    border-color: $athens-gray;
  }
}
</style>
